/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import { Banner00DataSource } from './data.source';
import './less/antMotionStyle.less';
import Banner0 from "./Banner0";
import Feature2 from "../Page2/Feature2";
import {Feature20DataSource} from "./data.source";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Banner0
          id="Banner0_0"
          key="Banner0_0"
          dataSource={Banner00DataSource}
          isMobile={this.state.isMobile}
      />,
      <Feature2
          id="Feature2_0"
          key="Feature2_0"
          dataSource={Feature20DataSource}
          isMobile={this.state.isMobile}
      />,
      // <Content9
      //   id="Content9_0"
      //   key="Content9_0"
      //   dataSource={Content90DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {children}
      </div>
    );
  }
}
