import React, { useState } from 'react';
import { Form, Input, Cascader, Select, Row, Col, notification, Button, message } from 'antd';
import { CheckCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import {post} from "../utils/http";
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 16,
        },
    },
};


const RegistrationForm = () => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        post('lrWebNeed/add',values).then(res => {
            if(res.success){
                notification.open({
                    message: '提交成功',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />,
                });
                form.resetFields()
            }else{
                notification.open({
                    message: '提交失败',
                    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
                });
            }
        })
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));
    const paddingTop='40px'
    return (
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                residence: ['zhejiang', 'hangzhou', 'xihu'],
                prefix: '86',
            }}
            scrollToFirstError
        >
            <Row gutter={24}>
                <Col span={12} style={{paddingTop:'60px'}}>
                    <Form.Item
                        name="changjing"
                        label="应用场景"
                        rules={[
                            {
                                required: true,
                                message: '请输入应用场景!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input size='large'/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:'60px'}}>&nbsp;</Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="name"
                        label="姓名"
                        rules={[
                            {
                                required: true,
                                message: '请输入姓名！',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="phone"
                        label="手机号"
                        rules={[
                            {
                                required: true,
                                message: '请输入手机号!',
                            },
                        ]}
                    >
                        <Input size='large'
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="company"
                        label="公司名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入公司名称！',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input  size='large'/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="email"
                        label="邮箱地址"
                        rules={[
                            {
                                type: 'email',
                                message: '请输入正确的邮箱地址!',
                            },
                        ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="address"
                        label="企业所在地"
                        rules={[
                            {
                                required: false,
                                message: '请输入企业所在地!',
                            },
                        ]}
                    >
                        <Input  size='large'/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingTop:paddingTop}}>
                    <Form.Item
                        name="remark"
                        label="请填写您的需求"
                        rules={[
                            {
                                required: false,
                                message: '请填写您的需求!',
                            },
                        ]}
                    >
                        <Input.TextArea size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{paddingTop:paddingTop}}>
                <Col span={24} style={{textAlign:'center'}}>
                    {/*<Form.Item >*/}
                        <Button type="primary" ghost onClick={()=>form.resetFields()}>
                            重置
                        </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    {/*</Form.Item>*/}
                </Col>
            </Row>
        </Form>
    );
};

export default RegistrationForm
