import React from 'react';
import BannerAnim from 'rc-banner-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";
import {Button} from "antd";
const { Element, Arrow, Thumb } = BannerAnim;
const BgElement = Element.BgElement;
class Banner3 extends React.Component {
    constructor() {
        super(...arguments);
        this.imgArray = [
            'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
            'https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg',
        ];
        this.state = {
            intShow: 0,
            prevEnter: false,
            nextEnter: false,
            thumbEnter: false,
            infoList:[]
        };
        [
            'onChange',
            'prevEnter',
            'prevLeave',
            'nextEnter',
            'nextLeave',
            'onMouseEnter',
            'onMouseLeave',
        ].forEach((method) => this[method] = this[method].bind(this));
    }

    onChange(type, int) {
        if (type === 'before') {
            this.setState({
                intShow: int,
            });
        }
    }
    componentDidMount() {
        get('lrWebBusiness/list',{isShow:1,column: 'sortNum', order: 'asc'}).then(res => {
            if(res.success){
                this.setState({infoList:res.result.records})
            }
        })
    }
    getNextPrevNumber() {
        const {infoList}=this.state
        let nextInt = this.state.intShow + 1;
        let prevInt = this.state.intShow - 1;
        if (nextInt >=infoList.length) {
            nextInt = 0;
        }
        if (prevInt < 0) {
            prevInt = infoList.length - 1;
        }

        return [prevInt, nextInt];
    }

    prevEnter() {
        this.setState({
            prevEnter: true,
        });
    }

    prevLeave() {
        this.setState({
            prevEnter: false,
        });
    }

    nextEnter() {
        this.setState({
            nextEnter: true,
        });
    }

    nextLeave() {
        this.setState({
            nextEnter: false,
        });
    }

    onMouseEnter() {
        this.setState({
            thumbEnter: true,
        });
    }

    onMouseLeave() {
        this.setState({
            thumbEnter: false,
        });
    }

    render() {
        const intArray = this.getNextPrevNumber();
        console.log(intArray);
        const thumbChildren = this.state.infoList.map((item, i) =>
            <span key={i} className='whiteFont'><i style={{ backgroundImage: `url(${baseFileUrl+item.industryIcon})` }} />{item.industryName}</span>
        );
        return (
            <div>
                <div className="blueFont bigTitle" style={{textAlign:'center',marginBottom:'20px'}}>业务范围</div>
                <BannerAnim
                    onChange={this.onChange}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    prefixCls="custom-arrow-thumb"
                     autoPlay //type="across"
                >
                    {
                        this.state.infoList.map((item,i)=>(
                            <Element key={i}
                                     prefixCls="banner-user-elem"
                            >
                                <BgElement
                                    key="bg"
                                    className="bg3"
                                    style={{
                                        backgroundImage: `url(${baseFileUrl+item.bannerUrl})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                />
                                <div style={{paddingTop:'10vh',paddingLeft:'11vh'}}>
                                    <TweenOne className="whiteFont banner3-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
                                        {item.title||''}
                                    </TweenOne>
                                    <TweenOne
                                        className="whiteFont banner3-text"
                                        animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                                    >
                                        {item.titleSub||''}
                                    </TweenOne>
                                    {
                                        item.jumpUrl?<TweenOne
                                            className="whiteFont banner3-text"
                                            animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                                        >
                                            <Button ghost onClick={()=>this.props.history.push({pathname:item.jumpUrl,state:item})}>了解更多>></Button>
                                        </TweenOne>
                                            :null
                                    }
                                </div>
                            </Element>
                        ))
                    }

                    {/*<Arrow arrowType="prev" key="prev" prefixCls="user-arrow" component={TweenOne}*/}
                    {/*       onMouseEnter={this.prevEnter}*/}
                    {/*       onMouseLeave={this.prevLeave}*/}
                    {/*       animation={{ left: this.state.prevEnter ? 0 : -120 }}*/}
                    {/*>*/}
                    {/*    <div className="arrow"></div>*/}
                    {/*    <TweenOneGroup*/}
                    {/*        enter={{ opacity: 0, type: 'from' }}*/}
                    {/*        leave={{ opacity: 0 }}*/}
                    {/*        appear={false}*/}
                    {/*        className="img-wrapper" component="ul"*/}
                    {/*    >*/}
                    {/*        <li style={{ backgroundImage:this.state.infoList.length>0? `url(${baseFileUrl+this.state.infoList[intArray[0]].industryIcon})`:'',height:'33px',width:'33px'}} key={intArray[0]} />*/}
                    {/*        {this.state.infoList.length>0?this.state.infoList[intArray[0]].industryName:''}*/}
                    {/*    </TweenOneGroup>*/}
                    {/*</Arrow>*/}
                    {/*<Arrow arrowType="next" key="next" prefixCls="user-arrow" component={TweenOne}*/}
                    {/*       onMouseEnter={this.nextEnter}*/}
                    {/*       onMouseLeave={this.nextLeave}*/}
                    {/*       animation={{ right: this.state.nextEnter ? 0 : -120 }}*/}
                    {/*>*/}
                    {/*    <div className="arrow"></div>*/}
                    {/*    <TweenOneGroup*/}
                    {/*        enter={{ opacity: 0, type: 'from' }}*/}
                    {/*        leave={{ opacity: 0 }}*/}
                    {/*        appear={false}*/}
                    {/*        className="img-wrapper"*/}
                    {/*        component="ul"*/}
                    {/*    >*/}
                    {/*        <li style={{ backgroundImage: this.state.infoList.length>0?`url(${baseFileUrl+this.state.infoList[intArray[1]].industryIcon})`:''}} key={intArray[1]} />*/}
                    {/*    </TweenOneGroup>*/}
                    {/*</Arrow>*/}
                    <Thumb prefixCls="user-thumb" key="thumb" component={TweenOne}
                        // animation={{ bottom: this.state.thumbEnter ? 0 : -70 }}
                    >
                        {thumbChildren}
                    </Thumb>
                </BannerAnim>
            </div>
        );
    }
}
export default Banner3;
