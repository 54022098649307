import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import {Row, Col, Button} from 'antd';
import { getChildrenToRender } from './utils';
import {Link} from "react-router-dom";
import { isImg } from './utils';
import logo from '../static/logo-2.png'
import ewm from '../static/ewm.png'
import top from '../static/top.png'
class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };
    backTop = () =>{
        document.documentElement.scrollTop=0
    }
    item5Btn = (key) => {
        this.props.history.push({pathname:'page6',state:{current:key}})
        if(key==='2'){
            setTimeout(()=>{
                document.documentElement.scrollTop=document.getElementById('zxzx').scrollHeight-230
            },100)
        }
        if(key==='3'){
            setTimeout(()=>{
                document.documentElement.scrollTop=document.getElementById('zxzx').scrollHeight+570
            },100)
        }
    }
  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      if(i===0){
          return (
              <Col key={i.toString()} {...itemProps} title={null} content={null} style={{paddingTop:'0'}}>
                   <Row>
                       <Col span={23}>
                           <div className='infoLineHeight' style={{marginTop:'60px'}}>
                               <div className='blueFont'>业务联系热线：</div>
                               <div className='whiteFont'>400-678-7996</div>
                               <div className='blueFont'>联系地址：</div>
                               <div className='whiteFont'>中国(山东)自由贸易试验区烟台片区</div>
                               <div className='whiteFont'>长江路300号业达智谷A座7层</div>
                               <div className='blueFont'>EMAIL：</div>
                               <div className='whiteFont'>zglingran@163.com</div>
                           </div>
                       </Col>
                       <Col span={1}>
                           <div className='infoLineHeight' style={{marginTop:'60px',background:'#aaa',width:'1px',height:'80%'}}>
                           </div>
                       </Col>
                   </Row>

              </Col>
          )
      }
        if(i===5){
            return (
                <Col key={i.toString()} {...itemProps} title={null} content={null} style={{paddingTop:'0'}}>

                    <div style={{paddingTop:'40px',textAlign:'center'}}>
                        <div className='blueFont'>定制个性化解决方案：</div>
                        <div className='whiteFont' style={{paddingTop:'10px'}}><Button ghost onClick={()=>this.item5Btn('2')}>免费定制咨询</Button></div>
                        <div style={{paddingTop:'40px'}}><img src={ewm} width="90" alt="img" /></div>
                        <div className='whiteFont' style={{paddingTop:'20px'}}>凌然科技官方微信</div>
                    </div>
                </Col>
            )
        }
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {i===4?
                childWrapper.children.map((item,i)=>(
                    <Link onClick={()=>this.item5Btn(item.key)}>{item.children}</Link>
                )):
                childWrapper.children.map((item,i)=>(
                <Link to={{pathname:item.href,state:item.state}}>{item.children}</Link>
            ))}
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    const  beian=window.location.href.includes("http://www.beicaibao.cn/")?'鲁ICP备19021302号-2':'鲁ICP备19021302号-1';
    return (
      <div {...props} {...dataSource.wrapper} >
        <OverPack {...dataSource.OverPack} style={{maxWidth:'1600px',padding:'20px 10px'}}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
            style={{maxWidth:'2560px'}}
          >
            <Col  md={12} xs={24} style={{paddingLeft:'30px'}}>
                <img src={logo} width="60" alt="img" />
            </Col>
            <Col style={{textAlign:'right',paddingRight:'30px',paddingTop:'10px'}}  md={12} xs={24}>
                {/*<img src={top} width="48" alt="img" onClick={this.backTop}/>*/}
                <div onClick={this.backTop} className='whiteFont' style={{fontSize:'18px'}}>TOP↑</div>
            </Col>
             <Col span={24} style={{paddingTop:'20px'}}> <div style={{background:'#aaa',height:'1px',margin:'0 35px'}}/></Col>
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
                <br/>
                  备案信息：<a href="http://beian.miit.gov.cn/" target="_blank" style={{color:'#cbcbcb',fontSize:'12px'}}>
                  {beian}
              </a>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
