import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import logo from '../static/logo-2.png'
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";

const { BgElement } = Element;
class Banner extends React.PureComponent {
    constructor ( props ) {
        super(props)
        this.state = {
            bannerList:[]
        }
    }
    componentDidMount() {
        get('lrWebBanner/list',{moduleType:3,isShow:1,column: 'sortNum', order: 'asc'}).then(res => {
            if(res.success){
                this.setState({bannerList:res.result.records})
            }
        })
    }
    clickB=(item)=>{
        this.props.history.push({pathname:item.jumpUrl,state:item})
    };
  render() {
    const { bannerList } = this.state;
    const childrenToRender = bannerList.map((item, i) => {
      return (
        <Element key={i.toString()} prefixCls="banner-user-elem">
          <BgElement key="bg" className="bg"
             style={{
                 backgroundImage: `url(${baseFileUrl+item.bannerUrl})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
             }}
          />
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            className="banner31-text-wrapper"
          >
            <div key='card'>
                <div key="logo" className="bigTitle blackFont">
                    {item.title||''}
                </div>
                <div key="content" className="banner31-content blackFont" >
                    {item.titleSub||''}
                </div>
                {
                    item.isJump?(
                        <Button type="primary" shape="round" key="button"  onClick={() =>this.clickB(item)}>
                            {item.linkName||'点击了解'}
                        </Button>
                    ):null
                }

            </div>
          </QueueAnim>
        </Element>
      );
    });
    return (
      <div className="banner31">
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <div className="banner31-wrapper" key="wrapper">
            <BannerAnim key="BannerAnim"
                        autoPlay
                       // type="across"
            >
              {childrenToRender}
            </BannerAnim>
          </div>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner31-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          {/*<DownOutlined />*/}
        </TweenOne>
      </div>
    );
  }
}

export default Banner;
