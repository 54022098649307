import React, { Component } from 'react';
import { Route,withRouter } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Layout/Nav3';
import Footer from './Layout/Footer1';
import './Layout/less/antMotionStyle.less';
import Home from './Home';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import recruit from './Page6/recruit';
import NewsIndex from './Page5/NewsIndex';

import {
  Nav30DataSource,
  Footer10DataSource,
} from './Layout/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   // 判断跳转路由不等于当前路由
  //   if (nextProps.location.pathname !== this.props.location.pathname) {
  //     console.log('路由改变触发');
  //   }
  // }
  render() {
    return (
          <div>
            <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} pathname={this.props.location.pathname} history={this.props.history}/>
            <Route exact path="/" component={Home} />
            <Route exact path="/page2" component={Page2} />
            <Route exact path="/page3" component={Page3} />
            <Route exact path="/page4" component={Page4} />
            <Route exact path="/page5" component={Page5} />
            <Route exact path="/page6" component={Page6} />
            <Route exact path="/newsIndex" component={NewsIndex} />
            <Route exact path="/recruit" component={recruit} />
            <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile}  history={this.props.history}/>
          </div>
    );
  }
}

export default withRouter(App);
