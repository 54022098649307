import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Tabs } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import fgt from '../static/fgt.png'
import diqiu from '../static/diqiu.png'
import fwldh from '../static/fwldh.png'
import yxh from '../static/yxh.png'
import gjh from '../static/gjh.png'
import qqh from '../static/qqh.png'
import jiazhiguan from '../static/jiazhiguan.png'
import shjiming from '../static/shjiming.png'
import wzbj from '../static/wzbj.png'
import rjzzbg from '../static/rjzz-bg.png'
import rjzzq from '../static/rjzzq.png'
import zlzs from '../static/zlzs.png'
import zlzsbj from '../static/zlzs-bj.png'
import rjzsbj from '../static/rjzs-bj.png'
import rhcpzs from '../static/rhcpzs.png'
import sbzcbj from '../static/sbzc-bj.png'
import sbzc from '../static/sbzc.png'
import ryzs from '../static/ryzs.png'
import ryzsbj from '../static/ryzs-bj.png'
import hjxm from '../static/hjxm.png'
import hjxmbj from '../static/hjxm-bj.png'
import gqpy from '../static/gqpy.png'
import gqpybj from '../static/gqpy-bj.png'
import wb from '../static/wb.png'
import qyzs from '../static/1rj-qyzs.png'
import cpzs from '../static/rj-cpzs.png'
import Content9 from "./Content9";
import {Content90DataSource} from './data.source'
const { TabPane } = Tabs;
class Content42  extends React.PureComponent {
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {
            current: '1',
            id:'0',
            //qqh,fwldh,yxh,gjh
            icons:[
                {name:'业务全球化',icon:qqh},
                {name:'产品国际化',icon:fwldh},
                {name:'技术有型化',icon:yxh},
                {name:'服务落地化',icon:gjh},
            ]
        };
    }
    callback=(key)=>{
        this.setState({
            current: key,
        });
    }
    componentDidMount() {
        if(this.props.current){
            this.callback(this.props.current)
        }
    }
    render() {
        const { ...tagProps } = this.props;
        const { dataSource, isMobile } = tagProps;
        delete tagProps.dataSource;
        delete tagProps.isMobile;
        const animType = {
            queue: isMobile ? 'bottom' : 'left',
        };
        return (
            <div {...tagProps} {...dataSource.wrapper} >
                <Tabs activeKey={this.state.current} onChange={this.callback} style={{backgroundColor: '#e3f1ff',height:'56px',paddingLeft:'40px'}}>
                    <TabPane tab={<div style={{fontSize:'18px'}}>公司介绍</div>} key="1">
                    </TabPane>
                    <TabPane tab={<div style={{fontSize:'18px'}}>企业文化</div>} key="2">
                    </TabPane>
                    <TabPane tab={<div style={{fontSize:'18px'}}>发展历程</div>} key="3">
                    </TabPane>
                    <TabPane tab={<div style={{fontSize:'18px'}}>企业荣誉</div>} key="4">
                    </TabPane>
                </Tabs>
                {
                    this.state.current==='1'?
                        <div {...dataSource.OverPack} style={{maxWidth: '1400',minHeight:'500px',padding:'0'}}>
                            <QueueAnim
                                type='bottom'
                                key="text"
                                leaveReverse
                                ease={['easeOutCubic', 'easeInCubic']}
                                {...dataSource.textWrapper}
                                component={Row}
                                componentProps={{
                                    md:6,
                                    xs: 24,
                                }}
                            >
                                <Col key="1" {...dataSource.textWrapper} md={6} xs={24}>
                                    <Row key='1'>
                                        <Col span={17} style={{textAlign:'right'}}>
                                            <h2 key="h1" style={{paddingTop:'80px'}}>
                                                公司业务
                                            </h2>
                                        </Col>
                                        <Col span={4} offset={3} style={{textAlign:'left'}}>
                                            <img src={fgt} height={100} style={{marginTop:'50px',marginBottom:'50px'}} alt=""/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col key="2" {...dataSource.textWrapper} md={18} xs={24}>
                                    <Row key='1'>
                                        <Col span={15} style={{textAlign:'left'}}>
                                            <div key="h1" style={{paddingTop:'60px',fontSize:'14px'}}>
                                                公司设有四大事业部，精益制造事业部、数据工业事业部、数据餐饮事业部、智能装备事业部；业务覆盖全国，华东业务中心、东北业务中心，华北业务中心。 公司拥有完备的服务体系，企业转型方案设计标准体系、企业管理专家资源池、企业信息化导入评审服务、企业信息化软件定制开发等服务。公司拥有多项软件著作权，实用新型专利，发明专利，商标等知识产权。
                                            </div>
                                        </Col>
                                        <Col span={6} offset={1} style={{textAlign:'left'}}>
                                            <img src={diqiu} height={120} style={{marginTop:'50px',marginBottom:'30px'}} alt=""/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col key="3" {...dataSource.textWrapper} md={6} xs={24}  style={{backgroundColor: '#e3f1ff'}}>
                                    <Row key='1'>
                                        <Col span={17} style={{textAlign:'right'}}>
                                            <h2 key="h1" style={{paddingTop:'80px'}}>
                                                服务行业
                                            </h2>
                                        </Col>
                                        <Col span={4} offset={3} style={{textAlign:'left'}}>
                                            <img src={fgt} height={100} style={{marginTop:'50px',marginBottom:'50px'}} alt=""/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col key="4" {...dataSource.textWrapper} md={18} xs={24} style={{backgroundColor: '#e3f1ff',height: '200px'}}>
                                    <Row key='1'>
                                        <Col span={22} style={{textAlign:'left'}}>
                                            <div key="h1" style={{paddingTop:'70px',fontSize:'14px'}}>
                                                服务包括装备制造、汽车制造、机械加工、电线电缆、智慧建筑、全屋定制、食品加工、模具行业、医药行业、电子行业、化工行业、新材料数十个细分行业的制造企业，提供智能、高效、安全、稳定的高性价比智能制造工厂整体解决方案，快速提升企业的生产运营效率。
                                            </div>
                                        </Col>
                                        {/*<Col span={6} offset={1} style={{textAlign:'left'}}>*/}
                                        {/*    <img src={diqiu} height={100} style={{marginTop:'50px',marginBottom:'30px'}} alt=""/>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Col>
                                <Col key="5" {...dataSource.textWrapper} md={6} xs={24}>
                                    <Row key='1'>
                                        <Col span={17} style={{textAlign:'right'}}>
                                            <h2 key="h1" style={{paddingTop:'80px'}}>
                                                企业愿景
                                            </h2>
                                        </Col>
                                        <Col span={4} offset={3} style={{textAlign:'left'}}>
                                            <img src={fgt} height={100} style={{marginTop:'50px',marginBottom:'50px'}} alt=""/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col key="6" {...dataSource.textWrapper} md={18} xs={24}>
                                    <QueueAnim
                                        type='bottom'
                                        key="text"
                                        leaveReverse
                                        ease={['easeOutCubic', 'easeInCubic']}
                                        {...dataSource.textWrapper}
                                        component={Row}
                                        componentProps={{
                                            md:6,
                                            xs: 24,
                                        }}
                                    >
                                        {this.state.icons.map((item,i)=>(
                                            <Col key={i} span={4}>
                                                <div style={{textAlign:'center',paddingTop:'70px'}}>
                                                    <img src={item.icon} width={40} alt=""/>
                                                    <div style={{fontSize:'14px',marginTop:'10px'}}>{item.name}</div>
                                                </div>

                                            </Col>
                                        ))}
                                    </QueueAnim>
                                </Col>
                            </QueueAnim>
                        </div>
                        :null
                }
                {
                    this.state.current==='2'?
                        <div {...dataSource.OverPack} style={{maxWidth: '2560px',minHeight:'500px',padding:'0',marginBottom:'30px',}}>
                            <Row>
                                <QueueAnim
                                    type='left'
                                    key="text1"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <Row key={1} md={24} xs={24} className="content42-wenhua content42-wenhua-card1">
                                        {!isMobile?
                                            <Col span={12} key={2} style={{paddingLeft:'159px',paddingTop:'15px'}}>
                                                <img src={shjiming} width={240} height={203} alt=""/>
                                            </Col>
                                        :null}
                                        <Col md={12} xs={24} key={3} style={{paddingLeft:'19px',paddingTop:'15px',display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <h2>企业使命</h2>
                                                <div >为更多企业提供转型升级方法及工具，用数据驱动工业前进。</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </QueueAnim>
                                <QueueAnim
                                    type='right'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <Row key={1} md={24} xs={24} className="content42-wenhua content42-wenhua-card2">

                                        <Col span={12} key={3} style={{paddingLeft:!isMobile?'263px':'19px',paddingTop:'15px',display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <h2>企业理念</h2>
                                                <div >让创业伙伴活得更加体面。</div>
                                            </div>
                                        </Col>
                                        {/*<Col span={12} key={2} style={{paddingLeft:'159px',paddingTop:'15px'}}>*/}
                                        {/*    <img src={qiyelinian} width={300} height={243} alt=""/>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </QueueAnim>
                                <QueueAnim
                                    type='left'
                                    key="text3"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <Row key={1} md={24} xs={24} className="content42-wenhua content42-wenhua-card3">
                                        {!isMobile?
                                            <Col span={12} key={2} style={{paddingLeft:'149px',paddingTop:'5px'}}>
                                                <img src={jiazhiguan} width={335} height={201} alt=""/>
                                            </Col>
                                        :null}
                                        <Col span={12} key={3} style={{paddingLeft:'19px',paddingTop:'15px',display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <h2>价值观</h2>
                                                <div>纵一苇之所如，凌万顷之茫然。</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </QueueAnim>
                            </Row>
                        </div>
                        :null
                }
                {
                    this.state.current==='3'?
                        <Content9
                            id="Content9_0"
                            key="Content9_0"
                            dataSource={Content90DataSource}
                            isMobile={this.props.isMobile}
                        />
                        :null

                }
                {
                    this.state.current==='4'?
                        <div {...dataSource.OverPack} style={{maxWidth: '2560px',minHeight:'500px',padding:'0',marginBottom:'30px',}}>
                            <Row>
                                <QueueAnim
                                    type='left'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',left:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wb}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'53px'}}>
                                                <h2>软件著作权</h2>
                                                <div >拥有数据中台、仓储、项目、设备、备菜宝、</div>
                                                <div>备菜宝商家版、人脸识别软件等多项著作权</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',right:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${rjzzbg}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={rjzzq} alt=""/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    type='right'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',right:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wzbj}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'133px'}}>
                                                <h2>专利证书</h2>
                                                <div >包括热敏打印机、受电弓、音频分发装置、</div>
                                                <div>便携式剃须装置、立体声耳机等5项专利</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',left:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${zlzsbj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={zlzs} alt=""/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    type='left'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',left:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wb}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'53px'}}>
                                                <h2>软件企业和产品证书</h2>
                                                <div>软件企业证书、智能云软件产品证书</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',right:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${rjzsbj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={qyzs} alt=""/>
                                            <img src={cpzs} alt=""/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    type='right'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',right:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wzbj}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'133px'}}>
                                                <h2>商标注册</h2>
                                                <div >拥有备菜宝和凌然2个商标所有权</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',left:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${sbzcbj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={sbzc} alt=""/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    type='left'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',left:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wb}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'53px'}}>
                                                <h2>体系认证</h2>
                                                <div>质量管理体系认证证书</div>
                                                <div>两化融合管理体系评定证书</div>
                                                <div>工业互联网产业联盟会员</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',right:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${ryzsbj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={ryzs} alt="" style={{width:'539px'}}/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    type='right'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',right:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wzbj}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft:'133px'}}>
                                                <h2>获奖项目</h2>
                                                <div>烟台黄渤海新区第八届（2023年）创业创新大赛 二等奖</div>
                                                <div>福莱山街道第五届创新创业大赛一等奖</div>
                                                <div>烟台市科技型中小企业创新竞技行动三等奖</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',left:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${hjxmbj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={hjxm} alt=""  style={{width:'539px'}}/>
                                        </div>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
            w                        type='left'
                                    key="text2"
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    componentProps={{
                                        md:24,
                                        xs: 24,
                                    }}
                                    style={{paddingTop:'30px',display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                                >
                                    <div key={1} className="content42-rongyu" style={{height:'375px'}}>
                                        <div  style={{zIndex:2,position:'absolute',left:'0',bottom:'0',display: 'flex', alignItems: 'center',background:`url(${wb}) no-repeat`,width:'649px',height:'354px'}}>
                                            <div style={{paddingLeft: '53px'}}>
                                                <div>国家级科技型中小企业</div>
                                                <div>高新技术企业</div>
                                            </div>
                                        </div>
                                        <div style={{zIndex:1,position:'absolute',right:'0',display: 'flex', alignItems: 'center',justifyContent:'center',background:`url(${gqpybj}) no-repeat`,width:'905px',height:'375px'}}>
                                            <img src={gqpy} alt="" style={{width:'539px'}}/>
                                        </div>
                                    </div>
                                </QueueAnim>
                            </Row>
                        </div>
                        :null
                }
            </div>
        );
    }


}

export default Content42;
