import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import {baseFileUrl} from "../utils/url";
function Content2(props) {
    const { ...tagProps } = props;
    const { hangyeList } = tagProps;
  return (
      <div>
          {<div className='home-page-wrapper content32-yingyong'>
              <OverPack className='home-page content32' style={{padding:'0 24px'}} playScale={0.3}>
                  <QueueAnim
                      type='bottom'
                      key="text"
                      leaveReverse
                      ease={['easeOutCubic', 'easeInCubic']}
                  >
                      <div key='zhili1' className='' style={{textAlign:'center',fontSize:'18px'}}>专注场景 量身定制</div>
                      <div key='zhili' className='blackFont bigTitle' style={{textAlign:'center',paddingTop:'15px'}}>为您的企业注入新型竞争能力</div>
                  </QueueAnim>
                  <QueueAnim
                      type='bottom'
                      key="text"
                      leaveReverse
                      ease={['easeOutCubic', 'easeInCubic']}
                      component={Row}
                      style={{paddingTop:'20px'}}
                  >
                  {
                      hangyeList.map((item,i)=>(
                          <Col md={2} xs={8} style={{textAlign:'center'}} offset={i===0?3:0} key={item.id}>
                              <div><img src={baseFileUrl+item.industryIcon2} width={30} height={30} alt=""/></div>
                              <div>{item.industryName}</div>
                          </Col>

                      ))
                  }
                  </QueueAnim>
              </OverPack>
          </div>}


      </div>
  );
}

export default Content2;
