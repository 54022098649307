import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import chahua from '../static/chahua1.png'
import zhusnhu from '../static/zhusnhu.png'
import kuaisu from '../static/kuaisu.png'
import anquan from '../static/anquan.png'
import chuangxinpingtai from '../static/chuangxinpingtai.png'
function Content2(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'left',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '+=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  const img = (
    <TweenOne
      key="img"
      animation={animType.one}
      resetStyle
      {...dataSource.imgWrapper}
      component={Col}
      componentProps={{
        md: dataSource.imgWrapper.md,
        xs: dataSource.imgWrapper.xs,
      }}
    >
      <span>
        <img src={chahua} width={400} alt="img" />
      </span>
    </TweenOne>
  );
  return (
    <div {...tagProps} {...dataSource.wrapper} style={{backgroundColor: '#e3f1ff',marginTop:'30px'}}>
      <OverPack {...dataSource.OverPack} component={Row}>
        {isMobile && img}
        <QueueAnim
          type={animType.queue}
          key="text"
          leaveReverse
          ease={['easeOutCubic', 'easeInCubic']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
            <Row key='p2'>
                <Col span={12} style={{paddingTop:'30px'}}>
                    <img src={zhusnhu} alt="" width={35}/> 专属定制化解决方案
                </Col>
                <Col span={12} style={{paddingTop:'30px'}}>
                    <img src={kuaisu} alt="" width={35}/> 快速方案输出
                </Col>
                <Col span={12} style={{paddingTop:'20px'}}>
                    <img src={anquan} alt="" width={35}/> 专业级安全支持
                </Col>
                <Col span={12} style={{paddingTop:'20px'}}>
                    <img src={chuangxinpingtai} alt="" width={35}/> 持续创新优化
                </Col>
            </Row>
        </QueueAnim>
        {!isMobile && img}
      </OverPack>
    </div>
  );
}

export default Content2;
