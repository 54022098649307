import React from 'react';
import QueueAnim from 'rc-queue-anim';
import {Row, Col, Button} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender, isImg } from './utils';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";

class Teams2 extends React.PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      infoList:[]
    };
  }
  getBlockChildren = (data) =>
    data.map((item, i) => {
      const {custLogo } = item;
      return (
        <Col key={i.toString()} className='block' md={4} xs={12}>
          <Row>
            <Col span={24}>
              <div className='teams2-image'>
                <img src={baseFileUrl+custLogo} alt="img" width={200} height={60} />
              </div>
            </Col>
            {/*<Col span={24}>*/}
            {/*  <QueueAnim className='teams2-textWrapper' type="bottom">*/}
            {/*    <div className='teams2-content'>{item.custName}</div>*/}
            {/*  </QueueAnim>*/}
            {/*</Col>*/}
          </Row>
        </Col>
      );
    });
  componentDidMount() {
    get('lrWebCust/list',{isShow:1,column: 'sortNum', order: 'asc',pageSize:100}).then(res => {
      if(res.success){
        this.setState({infoList:res.result.records})
      }
    })
  }
  render() {
    const { infoList } = this.state;
    const listChildren = this.getBlockChildren(infoList);
    return (
      <div className='home-page-wrapper teams2-wrapper'>
        <div className='home-page teams2'>
          <OverPack playScale={0.3}>
            <QueueAnim type="bottom" key="tween" leaveReverse>
              <QueueAnim
                type="bottom"
                key="block"
                className='block-wrapper'
                gutter={72}
                component={Row}
              >
                {listChildren}
              </QueueAnim>
            </QueueAnim>
            <div style={{textAlign:'center',marginBottom:'10px'}}><Button>查看全部案例>></Button></div>
          </OverPack>
        </div>

      </div>
    );
  }
}

export default Teams2;
