import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Carousel as AntCarousel, Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import Button from "antd/lib/button";

TweenOne.plugins.push(Children);

class Feature6 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.state = {
      current: 0,
    };
  }

  onTitleClick = (_, i) => {
    const carouselRef = this.carouselRef.current.childRefs.carousel;
    carouselRef.goTo(i);
  };

  onBeforeChange = (_, newIndex) => {
    this.setState({
      current: newIndex,
    });
  };

  getChildrenToRender = (dataSource) => {
    const { Carousel } = dataSource;
    const {
      children: childWrapper,
      ...carouselProps
    } = Carousel;



    const childrenToRender = childWrapper.map((item, ii) => {
      const { children, ...itemProps } = item;

      const childrenItem = children.map(($item, i) => {
        const { number, children: child, ...childProps } = $item;
        const numberChild = number.children.replace(/[^0-9.-]/g, '');
        const { unit, toText, ...numberProps } = number;
        return (
          <Col {...childProps} key={i.toString()}>
            <TweenOne
              {...numberProps}
              animation={{
                Children: {
                  value: parseFloat(numberChild),
                  floatLength:
                    parseFloat(numberChild) -
                      Math.floor(parseFloat(numberChild)) >
                    0
                      ? 2
                      : 0,
                  formatMoney: true,
                },
                duration: 1000,
                delay: 300,
                ease: 'easeInOutCirc',
              }}
              component="span"
            >
              0
            </TweenOne>
            {unit && <span {...unit}>{unit.children}</span>}
            <p {...child}>{child.children}</p>
          </Col>
        );
      });
      return (
        <div key={ii.toString()}>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={24} className="blueFont" style={{fontSize:'20px',fontWeight:'bold',}}>
                  山东凌然智能科技有限公司致力于企业的智能工厂建设，用高端IT技术推动国家制造业转型升级，为企业提供智能制造整体解决方案及实施落地服务，实现企业的降本增效与数字化转型升级。
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{fontSize:'16px',fontWeight:'400',marginTop:'10px'}}>
                  凌然科技是高新技术企业、科技型中小企业、信通院工业互联网产业联盟会员单位、荣获烟台市创新竞技三等奖。
                  公司秉承“赋能数据.驱动未来”的理念，凭借不断的自主创新，持续提升服务质量，已获得软件著作权多项，发明专利、实用新型专利多项，商标多项等知识产权。
                </Col>
              </Row>
              <Row>
                <Col span={24} className="blueFont">
                  <Button ghost>了解更多>></Button>
                </Col>
              </Row>
            </Col>
            <Col span={10} offset={2}>
              <QueueAnim type="bottom" component={Row} {...itemProps}>
                {childrenItem}
              </QueueAnim>
            </Col>
          </Row>

        </div>
      );
    });

    return (
      <QueueAnim
        key="queue"
        leaveReverse
        type="bottom"
        delay={[0, 100]}
        ref={this.carouselRef}
      >
        <AntCarousel
          {...carouselProps}
          key="carousel"
          infinite={false}
          beforeChange={this.onBeforeChange}
        >
          {childrenToRender}
        </AntCarousel>
      </QueueAnim>
    );
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div>
          <OverPack {...dataSource.OverPack} style={{marginTop:'180px'}}>
            {this.getChildrenToRender(dataSource)}
          </OverPack>
        </div>
      </div>
    );
  }
}
export default Feature6;
