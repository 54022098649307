import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import {Steps,Form,Row,Col,Button,Input} from "antd";
import bjlr from '../static/bj-lr.png'
import xzk from '../static/xzk.png'
import jt from '../static/jt.png'
import ptfwbj from '../static/ptfw-bj.png'
import lxwm from '../static/lxwm.png'
import {enquireScreen} from "enquire-js";
import {get} from "../utils/http";
import RegistrationForm from "./form";
const { Step } = Steps;
class Content6 extends React.PureComponent {
  state = {
    current: 2,
    infoList:[]
  };

  componentDidMount() {

    get('lrWebJoin/list',{isShow:1,column: 'sortNum', order: 'asc'}).then(res => {
      if(res.success){
        this.setState({infoList:res.result.records})
      }
    })
  }
    showInfo=(item)=>{
      const { infoList } = this.state;
        this.props.history.push({pathname:'recruit',state: {item,infoList}})
    }
  render() {
    const { ...props } = this.props;
    const { dataSource,isMobile } = props;
    const { infoList } = this.state;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div style={{textAlign:'center',fontSize:'22px',color:'#2777BF',marginBottom:'60px'}}>招贤纳士</div>
          <div className='block-wrapper' style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
            <QueueAnim
                type='bottom'
                key="text2"
                leaveReverse
                ease={['easeOutCubic', 'easeInCubic']}
                component={Row}
            >
              {
                infoList.map((item,i)=>(
                    <Col key={i} md={12} xs={24}>
                      <div style={{width:'470px',height:'100px',background:`url(${xzk}) no-repeat`,backgroundSize:'100%',marginLeft:'70px'}}  onClick={()=>this.showInfo(item)}>
                        <Row className='blueFont' style={{padding:'20px 0px 0 20px'}}>
                          <Col span={18}>
                            <div style={{fontSize:'18px'}}>{item.joinPosition}</div>
                            <div>{item.address||''} | {item.updateTime?`${item.updateTime.substring(0,4)}年${item.updateTime.substring(5,7)}月${item.updateTime.substring(8,10)}日`:`${item.createTime.substring(0,4)}年${item.createTime.substring(5,7)}月${item.createTime.substring(8,10)}日`}</div>
                          </Col>
                          <Col span={6} style={{textAlign:'right',paddingRight:'20px'}}><img src={jt} width={30} style={{paddingTop:'10px'}} alt=""/></Col>
                        </Row>
                      </div>
                    </Col>
                ))
              }
            </QueueAnim>
          </div>

        </div>
        {/*{!isMobile?*/}
        {/*    <div style={{position:'absolute',right:'100px',top:'200px'}}><img src={bjlr} width={400} alt=""/></div>*/}
        {/*    :null*/}
        {/*}*/}
        <div id='zxzx' style={{width:'100vw',height:'800px',background:`url(${ptfwbj}) no-repeat`,backgroundSize:'100%',marginTop:'60px'}}>
          <div className='blueFont' style={{textAlign:'center',fontSize:'24px',paddingTop:'60px'}}>平台服务在线咨询</div>
          <div className='home-page content6'>
              <RegistrationForm/>
          </div>
        </div>
          <div id='lxwm' style={{width:'100vw'}}>
              <div className='blueFont' style={{textAlign:'center',fontSize:'24px',paddingTop:'20px',paddingBottom:'20px'}}>联系我们</div>
              <img src={lxwm} width='100%' alt=""/>
          </div>
      </div>
    );
  }
}

export default Content6;
