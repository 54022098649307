import React from 'react';
export const Nav30DataSource = {
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://gw.alipayobjects.com/zos/basement_prod/b30cdc2a-d91c-4c78-be9c-7c63b308d4b3.svg',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'text' }],
        },

      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/page2',
          children: [{ children: '产品与服务', name: 'text' }],
        },
        // subItem: [
        //   {
        //     name: 'sub0',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //               'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/page3',
          children: [{ children: '客户案例', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/page4',
          children: [{ children: '走进凌然', name: 'text' }],
        },
      },
      {
        name: 'item4',
        className: 'header3-item',
        children: {
          href: '/page5',
          children: [{ children: '新闻动态', name: 'text' }],
        },
      },
      {
        name: 'item5',
        className: 'header3-item',
        children: {
          href: '/page6',
          children: [{ children: '加入我们', name: 'text' }],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1 home-page', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 5,
        className: 'block',
      },
      // {
      //   name: 'block01',
      //   xs: 24,
      //   md: 4,
      //   className: 'block',
      //   title: {
      //     className: 'logo',
      //     children:
      //         'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
      //   },
      //   childWrapper: {
      //     className: 'slogan',
      //     children: [
      //       {
      //         name: 'content0',
      //         children: 'Animation specification and components of Ant Design.',
      //       },
      //     ],
      //   },
      // },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '产品与服务' },
        childWrapper: {
          children: [
            { name: 'link0', href: 'page2', children: '数据工业管理' },
            { name: 'link1', href: 'page2', children: '智能终端管理' },
            { name: 'link2', href: 'page2', children: '智慧建筑管理' },
            { name: 'link3', href: 'page2', children: '数据餐饮管理' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 3,
        className: 'block',
        title: { children: '走进凌然' },
        childWrapper: {
          children: [
            { href: 'page4', name: 'link0', children: '关于我们',state:{current:'1'} },
            { href: 'page4', name: 'link1', children: '企业文化',state:{current:'2'} },
            { href: 'page4', name: 'link1', children: '发展历程',state:{current:'3'} },
            { href: 'page4', name: 'link2', children: '企业荣誉',state:{current:'4'} },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 3,
        className: 'block',
        title: { children: '新闻中心' },
        childWrapper: {
          children: [
            { href: 'page5', name: 'link0', children: '公司新闻',state:{current:'1'} },
            // { href: 'page5', name: 'link1', children: '媒体报道',state:{current:'2'} },
            { href: 'page5', name: 'link1', children: '行业资讯',state:{current:'3'} },
            { href: 'page5', name: 'link1', children: '专业知识',state:{current:'4'}},
          ],
        },
      },
      {
        name: 'block4',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: {
          children: [
            { href: 'page6', name: 'link1', children: '加入我们',key:'1' },
            { href: 'page6', name: 'link1', children: '在线留言',key:'2' },
            { href: 'page6', name: 'link0', children: '联系方式',key:'3' },
          ],
        },
      },
      {
        name: 'block5',
        xs: 24,
        md: 5,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        {/*©2021 by <a href="/">lingran</a> All Rights*/}
        {/*Reserved*/}
        Copyright ©2021 by lingran All Rights Reserved.  山东凌然智能科技有限公司 版权所有

      </span>
    ),
  },
};
