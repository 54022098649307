import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import {Link} from "react-router-dom";
import {Steps, Timeline, Avatar, Row} from "antd";
import bjlr from '../static/bj-lr.png'
import {get} from "../utils/http";
const { Step } = Steps;
class Content9 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newsInfo:{}
    };
  }
  componentDidMount() {
    const { newsInfo } = this.props;
    if(newsInfo){

      this.setState({newsInfo})
      get('lrWebNews/queryById',{id:newsInfo.id}).then(res => {
        if(res.success){
          this.setState({newsInfo:{...res.result,newsType_dictText:newsInfo.newsType_dictText}})
        }
      })
    }
  }

  render() {
    const { newsInfo } = this.state;
    return (
      <div className='home-page-wrapper'>
        <div className='home-page' style={{padding:'20px 12px'}}>
          <QueueAnim
              key="queue"
              type="right"
              leaveReverse
              style={{minHeight:'500px'}}
          >
          <div key={newsInfo.id+'1'} className='blackFont' style={{fontSize:'20px'}}><Link to='page5'>新闻动态</Link> <span className='blueFont'>>{newsInfo.newsType_dictText}</span></div>
          <hr key={newsInfo.id+'2'}/>
          <div key={newsInfo.id+'3'} className='blackFont' style={{fontSize:'20px',fontWeight:'bold'}}>{newsInfo.newsTitle}</div>
          <div key={newsInfo.id+'4'} style={{margin:'10px 0px'}}>{newsInfo.newsDate}</div>
          <div key={newsInfo.id+'5'} dangerouslySetInnerHTML={{__html:newsInfo.newsContent}} />
          </QueueAnim>
        </div>
      </div>
    );
  }
}

export default Content9;
