export const Banner31DataSource = {
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title: '互联网一站式服务平台',
        content: '企业级智能方案服务商',
        button: { href: '/page2', text: '完整案例→' },
      },
      {
        name: 'elem1',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title:'互联网一站式服务平台',
        content:'一个高效的页面动画解决方案',
        button: { href: '/page2', text: '点击了解2' },
      },
      {
        name: 'elem2',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title:'互联网一站式服务平台',
        content:  '一个高效的页面动画解决方案',
        // button: { className: 'banner1-button', children: '点击了解' },
      },
    ],
  },
};
export const Feature70DataSource =  [
    {
      hangye:'建材行业',children: [
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
      ]
    },
    {
      hangye:'模具行业',children: [
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
      ]
    },
    {
      hangye:'化工行业',children: [
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
        {
          image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
          title: '身份证',
          content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
        },
      ]
    },
  ];
