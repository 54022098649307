import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col,Tabs,Button } from 'antd';
import fwldh from '../static/fwldh.png'
import yxh from '../static/yxh.png'
import gjh from '../static/gjh.png'
import qqh from '../static/qqh.png'

import {Content90DataSource} from './data.source'
import {enquireScreen} from "enquire-js";
import axios from "axios";
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";
const { TabPane } = Tabs;
class content51  extends React.PureComponent {
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {
            current: '1',
            id:'0',
            //qqh,fwldh,yxh,gjh
            icons:[
                {name:'业务全球化',icon:qqh},
                {name:'产品国际化',icon:fwldh},
                {name:'技术有型化',icon:yxh},
                {name:'服务落地化',icon:gjh},
            ],
            newsType:[
                {name:'公司动态',key:'1'},
                //{name:'媒体报道',key:'2'},
                {name:'行业资讯',key:'3'},
                {name:'专业知识',key:'4'},
            ],
            hotList:[],
            pageSize:5,
            newsList:[],
            currentPage:1,
            pages:1
        };
    }
    callback=(key)=>{
        // for (let i = 0; i < this.state.newsType; i++) {
        //     this.state.newsType[i].newsList=[];
        // }
        this.setState({
            current: key,
            currentPage:1,
            newsList:[]
        }, () => {
            setTimeout(()=>{
                this.getNewsList()
            },500)
        });

    }
    componentDidMount() {
        let param={column: 'sortNum', order: 'asc',isShow:1,pageSize:8}
       get('lrWebNewsNoContent/list',{isHotspot:1,...param}).then(res => {
         if(res.success){
             this.setState({hotList:res.result.records})
         }
       })
        if(this.props.current){
            this.setState({
                current: this.props.current,
                currentPage:1,
                newsList:[]
            }, () => {
                this.getNewsList()
            });
        }else{
            this.getNewsList()
        }
    }
    showInfo=(item)=>{
        this.props.history.push({pathname:'newsIndex',state:item})
    }
    getNewsList=()=>{

        let param={column: 'sortNum', order: 'asc',isShow:1,pageNo:this.state.currentPage,pageSize:this.state.pageSize}
        get('lrWebNewsNoContent/list',{newsType:this.state.current,...param}).then(res => {
            if(res.success){
                this.setState({
                    newsList:this.state.newsList.concat(res.result.records),
                    currentPage:res.result.current+1,
                    pages:res.result.pages,
                })
            }
        })
    }
    getNews=(key)=> {
        const { ...tagProps } = this.props;
        const { dataSource, isMobile } = tagProps;
        const{hotList,current,pages,currentPage,newsList}=this.state;
       return (<QueueAnim
           type={['right', 'left']}
            key="text"
            leaveReverse
            ease={['easeOutCubic', 'easeInCubic']}
            {...dataSource.textWrapper}
            component={Row}
            className='home-page content51'
           style={{minHeight:'300px'}}
        >
            {
                newsList.map((item, i) => (
                    <Col key={this.state.current + '-' + i} span={24}>
                        <Row style={{margin: '20px 20px 20px 0'}} onClick={()=>this.showInfo(item)}>
                            <Col span={7}><img src={baseFileUrl + item.newsImg} width='100%' alt=""/></Col>
                            <Col span={17}>
                                <div style={{paddingLeft: '20px'}}>
                                    <div style={{fontSize: '18px', fontWeight: 'bold'}}>{item.newsTitle}</div>
                                    <div
                                        style={{height: '100px'}}>{item.newsSketch && item.newsSketch.length > 150 ? item.newsSketch.substring(0, 150) + '...' : item.newsSketch}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ))
            }
        </QueueAnim>)
    }
    render() {
        const { ...tagProps } = this.props;
        const { dataSource, isMobile } = tagProps;
        delete tagProps.dataSource;
        delete tagProps.isMobile;
        const{hotList,newsList,pages,currentPage,newsType}=this.state;
        const animType = {
            queue: isMobile ? 'bottom' : 'left',
        };
        return (
            <div {...tagProps} {...dataSource.wrapper} >
                <QueueAnim
                    type='bottom'
                    key="text"
                    leaveReverse
                    ease={['easeOutCubic', 'easeInCubic']}
                    {...dataSource.textWrapper}
                    component={Row}
                    className='home-page content51'
                    style={{padding:'10px 0'}}
                >
                    <Col span={24} key={1} className=' content51 blackFont' style={{fontSize:'20px',fontWight:'bold'}}>热点资讯</Col>
                </QueueAnim>
                <QueueAnim
                    type='bottom'
                    key="text"
                    leaveReverse
                    ease={['easeOutCubic', 'easeInCubic']}
                    {...dataSource.textWrapper}
                    component={Row}
                    className='home-page content51'
                    // componentProps={{
                    //     md: dataSource.textWrapper.md,
                    //     xs: dataSource.textWrapper.xs,
                    // }}
                >
                    {
                        hotList.map((item,i)=>(
                            <Col key={i} md={6} xs={24}>
                                <div style={{margin:'0 20px 20px 0',cursor:'pointer'}} onClick={()=>this.showInfo(item)}>
                                    <div><img src={baseFileUrl+item.newsImg} width='100%' alt=""/></div>
                                    <div style={{fontWeight:'bold',paddingTop:'10px'}}>{item.newsTitle}</div>
                                </div>
                            </Col>
                        ))
                    }
                </QueueAnim>
                <div className='home-page content51'>
                    <Tabs activeKey={this.state.current} onChange={this.callback} style={{}}>
                        {newsType.map((item)=>(
                            <TabPane tab={<div style={{fontSize:'18px'}}>{item.name}</div>} key={item.key}>
                                {this.getNews(item.key)}

                            </TabPane>
                        ))}
                    </Tabs>
                    <div style={{padding:'0 20px'}}>
                        {
                            currentPage <= pages ?
                                <Button onClick={() => this.getNewsList()} block style={{marginBottom:'20px'}}>更多资讯>></Button>
                                :
                                <div style={{paddingTop:'20px',marginBottom:'20px',width:'100%',textAlign:'center'}}>没有更多了~</div>
                        }
                    </div>

                </div>


            </div>
        );
    }


}

export default content51;
