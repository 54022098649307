import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import chahua from '../static/chahua1.png'
import ptys from '../static/ptys.png'
import shuzi1 from '../static/shuzi1.png'
import shuzi2 from '../static/02.png'
import shuzi3 from '../static/03.png'
import shuzi4 from '../static/04.png'
import jsys from '../static/jsys.png'
import cxys from '../static/cxys.png'
import msys from '../static/msys.png'
import yycj2 from '../static/pic_yycj.png'
import hlw from '../static/hlw.png'
import gyhla from '../static/gyhla.png'
import gygltj from '../static/gygltj.png'
import gy from '../static/gy.png'
import yingyong from '../static/yingyong.png'
import gywyy from '../static/gywyy.png'
import bysj from '../static/bysj.png'
import byjs from '../static/byjs.png'
import sjcy from '../static/sjcy.png'
import canyin from '../static/canyin.png'
import jianzhu from '../static/jianzhu.png'
import zhjz from '../static/zhjz.png'
class Content2 extends React.PureComponent {
    constructor ( props ) {
        super(props)
        this.state = {
            infoList:[
                {
                    icon:hlw,
                    title:'工业互联网平台',
                    titleSub:'基于Hi-PaaS数据中台，为工业企业提供标准数据接口及应用服务平台',
                    content:'针对不同行业提供需求分析、智造服务集成，有效缩短产业供应链，提升区域智造效能',
                    btnColor:'#1992FF',
                    img:gyhla
                },
                {
                    icon:gy,
                    title:'工业管理集成套件',
                    titleSub:'利用行业专业知识，结合工业生产实践经验，为企业提供了灵活可配的管理平台及应用',
                    content:'项目管理中心、资源管理中心、智造管理中心、仓储管理中心、设备管理中心、风险管理中心、边缘数据处理中心、行业案例管理中心',
                    btnColor:'#665EF1',
                    img:gygltj
                },
                {
                    icon:yingyong,
                    title:'工业微应用',
                    titleSub:'根据企业不同需求，设计了16项微服务应用及工业APP',
                    content:'协同管理、风险管理、产品管理、订单管理、工艺BOM管理、采购管理、计划管理、生产管理、质量管理、仓储管理、成本管理、设备管理、客户/供应商管理、部门角色管理、综合数据集成服务',
                    btnColor:'#FFAC1C',
                    img:gywyy
                },
                {
                    icon:bysj,
                    title:'智能终端管理',
                    titleSub:'以设备通讯结合数据采集技术，对不同数据终端进行数据过滤预处理，降低主题IaaS层压力',
                    content: '边缘数据集成处理器、智能传感器、数据采集终端、数据采集软件、远程运维管理',
                    btnColor:'#00DD29',
                    img:byjs
                },
                {
                    icon:jianzhu,
                    title:'智慧建筑',
                    titleSub:'智慧建筑通过B/S访问模式、融合平台建设、云计算服务以及嵌入式控制器管理，实现建筑设备自动化、办公智能化、通信网络自动化',
                    content: '爬架安全管理 、建筑安全管理、建筑环境监控平台、建筑人员管理平台',
                    btnColor:'#00DD29',
                    img:zhjz
                },
                {
                    icon:canyin,
                    title:'数据餐饮',
                    titleSub:'数据餐饮通过生鲜采购端、门店管理端、外卖管理端、物流管理端、后台服务端等系统科学管理打通餐饮供应链关系，解决供求信息不透明问题，减少物流能力浪费',
                    content: '备菜宝餐饮供应链管理平台',
                    btnColor:'#00DD29',
                    img:sjcy
                }
            ],
            youshiList:[
                {
                    img:ptys,
                    img2:shuzi1,
                    title:'资源优势',
                    content:'业务范围已覆盖全国，形成东北、华东、华北区域业务中心，企业自身拥有提供核心竞争力基础的资产资源及内部资源和外部资源。',
                },{
                    img:msys,
                    img2:shuzi2,
                    title:'服务优势',
                    content:'坚持以精准定制模式为核心，专注为企业打造适合自己的互联网工厂赋能，帮助企业围绕自身经营体验，实现高精度、高效率的定制转型，为企业提供转型一站式服务。',
                },{
                    img:jsys,
                    img2:shuzi3,
                    title:'技术优势',
                    content:'自主研发国内首个跨行业工业数据服务集成管理平台，具备强大的数据处理能力，提供开放应用对接，拥有完善的项目调研及高效的项目研发团队，敏锐的市场嗅觉，客户满意度及市场信誉度高。',
                },{
                    img:cxys,
                    img2:shuzi4,
                    title:'创新优势',
                    content:'致力于工业企业转型升级方案设计及信息化软件研发，持续投入科技创新，推进工业化与信息化融合，累计商标、专利、软件著作数量20余项，覆盖领域广泛，并获得多家企业和机构的支持和认可。',
                },
            ]
        }
    }
    render() {
        const { isMobile } = this.props;
        const { infoList,youshiList } = this.state;
        const animType = {
            queue: isMobile ? 'bottom' : 'left',
            one: isMobile
                ? {
                    scaleY: '+=0.3',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                }
                : {
                    x: '+=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                },
        };
        const getContentAnimType=(i) => {
            return   isMobile ? 'bottom' : i%2===0?'left':'right'
        };
        const getImgAnimType=(i) => {
            return   isMobile
                ? {
                    scaleY: '+=0.3',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                }
                : {
                    x: i%2===0?'+=30':'-=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                }
        };
        const getImg = (item,i)=>(
            <TweenOne
                key={"img"+i+Math.random()}
                animation={getImgAnimType(i)}
                resetStyle
                className='content22-img'
                component={Col}
                componentProps={{
                    md: 12,
                    xs: 24,
                }}
            >
      <span>
        <img src={item.img} width="350" alt="img" />
      </span>
            </TweenOne>
        );
        return (
            <div>
                {
                    infoList.map((item, i) =>
                        <div key={i} className='home-page-wrapper content22-wrapper'>
                            <OverPack className='home-page content22' playScale={0.3} component={Row}>
                                {(isMobile||i%2===1) && getImg(item,i)}
                                <QueueAnim
                                    type={getContentAnimType(i)}
                                    key={"text"+i}
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    className='content22-text'
                                    component={Col}
                                    componentProps={{
                                        md: 12,
                                        xs: 24,
                                    }}
                                >
                                    <h2 key={"h1"+i} className='content22-title'>
                                        <img src={item.icon} width={35} alt=""/> {item.title}
                                    </h2>
                                    <div key={"h1"+i} style={{paddingTop:'20px',fontSize:'18px'}}>
                                        {item.titleSub}
                                    </div>
                                    <div key={"p"+i} className='content22-content'>
                                        {item.content}
                                    </div>
                                </QueueAnim>
                                {(!isMobile||i%2===0) && getImg(item,i)}
                            </OverPack>
                        </div>
                    )
                }{
                <div className='home-page-wrapper content22-youshi'>
                    <OverPack className='home-page content22' style={{padding:'60px 24px'}} playScale={0.3} component={Row}>
                        <QueueAnim
                            type='bottom'
                            key="text"
                            leaveReverse
                            ease={['easeOutCubic', 'easeInCubic']}
                            component={Col}
                            componentProps={{
                                md: 24,
                                xs: 24,
                            }}
                        >
                            <div key='zhili' className='blueFont bigTitle' style={{textAlign:'center'}}>普适中国企业的互联网应用平台</div>
                        </QueueAnim>
                        {
                            youshiList.map((item,i)=>(
                                <QueueAnim
                                    type={i%2===0?'left':'right'}
                                    key={i}
                                    leaveReverse
                                    ease={['easeOutCubic', 'easeInCubic']}
                                    component={Col}
                                    style={{marginTop:'55px',textAlign:'center',display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'}}
                                    componentProps={{
                                        md: 12,
                                        xs: 24,
                                    }}
                                >
                                    <div key='11' className='card22'>
                                        <Row style={{paddingTop:'35px'}}>
                                            <Col span={8} offset={6}><img src={item.img} width='100%' alt=""/></Col>
                                            <Col span={7}><img src={item.img2} width='100%' alt=""/></Col>
                                        </Row>
                                        <div className="blackFont" style={{textAlign:'center',fontSize:'22px',fontWeight:'bold',height:'80px',lineHeight:'80px'}}>{item.title}</div>
                                        <div style={{paddingLeft:'40px',paddingRight:'40px'}}>{item.content}</div>
                                    </div>
                                </QueueAnim>
                            ))
                        }
                    </OverPack>
                </div>
            }
                {<div className='home-page-wrapper content22-yingyong'>
                    <OverPack className='home-page content22' style={{padding:'20px 24px 60px 24px'}} playScale={0.3} component={Row}>
                        <QueueAnim
                            type='bottom'
                            key="text"
                            leaveReverse
                            ease={['easeOutCubic', 'easeInCubic']}
                            component={Col}
                            componentProps={{
                                md: 24,
                                xs: 24,
                            }}
                        >
                            <div key='zhili' className='blueFont bigTitle' style={{textAlign:'center'}}>应用场景</div>
                            <img src={yycj2} width="100%" style={{padding:'20px'}} alt=""/>
                        </QueueAnim>
                    </OverPack>
                </div>}
            </div>
        );
    }



}

export default Content2;
