export const Banner11DataSource = {
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title: '互联网一站式服务平台',
        content: '企业级智能方案服务商',
        button: { href: '/page2', text: '点击了解' },
      },
      {
        name: 'elem1',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title:'互联网一站式服务平台',
        content:'一个高效的页面动画解决方案',
        button: { href: '/page2', text: '点击了解2' },
      },
      {
        name: 'elem2',
        bg: "https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg",
        title:'互联网一站式服务平台',
        content:  '一个高效的页面动画解决方案',
        // button: { className: 'banner1-button', children: '点击了解' },
      },
    ],
  },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: { className: 'banner2-title', children: 'Ant Motion' },
        content: {
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    children: [
      {
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '个' },
              toText: true,
              children: '46',
            },
            children: { className: 'feature6-text', children: '服务行业领域' },
          },
          {
            md: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '项' },
              toText: true,
              children: '5',
            },
            children: { className: 'feature6-text', children: '实用新型专利' },
          },
          {
            md: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+家' },
              toText: true,
              children: '300',
            },
            children: { className: 'feature6-text', children: '服务客户' },
          },{
            md: 12,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '项' },
              toText: true,
              children: '16',
            },
            children: { className: 'feature6-text', children: '软件著作权' },
          },
        ],
      }
    ],
  },
};
export const Feature70DataSource = {
  blockWrapper: [
    {
      image: 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
      title: '身份证',
      content: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
    },
  ],
};
export const Teams20DataSource = {
  block:  [
      {
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },{
        name: 'block0',
        image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
      },
    ],
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: '专业的企业级服务' },
  content: {
    className: 'content2-content',
    children:
        '凌然科技365天全年无休，为您的企业提供无忧顺畅高效的管理渠道，从项目订单、客户管理、工艺管理、计划管理、生产制造、品质管控、设备管理、交付管理到售后服务，全业务流程、全生产要素、全价值链的数字化转型升级。',
  },
};
export const News0DataSource = [
    {
      id:'0',
      title: '创鑫智慧物流数据基地正式投入使用',
      date: '2021-06-24',
      content: '烟台创鑫自动化是国家级高新技术企业，致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技，共同打造基于过程数据驱动管理价值的云化数据基地，平台今日正式上线运行！致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技。',
      image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
    },{
      id:'1',
      title: '创鑫智慧物流数据基地正式投入使用',
      date: '2021-06-24',
      content: '烟台创鑫自动化是国家级高新技术企业，致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技，共同打造基于过程数据驱动管理价值的云化数据基地，平台今日正式上线运行！致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技。',
      image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
    },{
      id:'2',
      title: '创鑫智慧物流数据基地正式投入使用',
      date: '2021-06-24',
      content: '烟台创鑫自动化是国家级高新技术企业，致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技，共同打造基于过程数据驱动管理价值的云化数据基地，平台今日正式上线运行！致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技。',
      image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
    },{
      id:'4',
      title: '创鑫智慧物流数据基地正式投入使用',
      date: '2021-06-24',
      content: '烟台创鑫自动化是国家级高新技术企业，致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技，共同打造基于过程数据驱动管理价值的云化数据基地，平台今日正式上线运行！致力于智能物流机器人研发\'生产，为强化管理，携手凌然科技。',
      image: 'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
    },
  ];
