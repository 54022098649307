import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import {Steps,Timeline,Avatar} from "antd";
import bjlr from '../static/bj-lr.png'
import {get} from "../utils/http";
const { Step } = Steps;
class Content9 extends React.PureComponent {
  state = {
    current: 3,
    yearList:[],
    infoList:[]
  };
  onChange = current => {
    console.log('onChange:', current);
    this.setState({ current },()=>{
      this.getData()
    });
  };
  componentDidMount() {
    let yearList=[];
    for (let i = 0; i < 4; i++) {
      yearList.push(new Date().getFullYear()-(3-i))
    }
    this.setState({ yearList },()=>{
      this.getData()
    });

  }
  getData=()=>{
    const {current,yearList}=this.state;
    console.log(current,yearList);
    const initDate_begin=yearList[current]+'-01-01';
    const initDate_end=yearList[current]+'-12-31';
    get('/lrWebDevHistory/list',{isShow:1,column: 'initDate', order: 'desc',pageSize:100,initDate_begin,initDate_end}).then(res => {
      if(res.success){
        this.setState({infoList:res.result.records})
      }
    })
  }
  getBlockChildren = (block, i) => {
    const { isMobile } = this.props;
    const item = block.children;
    const textWrapper = (
      <QueueAnim
        key="text"
        leaveReverse
        delay={isMobile ? [0, 100] : 0}
        {...item.textWrapper}
      >
        <div key="time" {...item.time}>
          {item.time.children}
        </div>
        <h2 key="title" {...item.title}>
          <i {...item.icon}>
            <img src={item.icon.children} alt="img" />
          </i>
          {item.title.children}
        </h2>
        <div key="p" {...item.content}>
          {item.content.children}
        </div>
      </QueueAnim>
    );
    return (
      <div key={i.toString()} {...block}>
        {isMobile && textWrapper}
        <QueueAnim
          className="image-wrapper"
          key="image"
          type={isMobile ? 'right' : 'bottom'}
          leaveReverse
          delay={isMobile ? [100, 0] : 0}
          {...item.imgWrapper}
        >
          <div key="image" {...item.img}>
            <img src={item.img.children} alt="img" />
          </div>
          <div key="name" className="name-wrapper">
            <div key="name" {...item.name}>
              {item.name.children}
            </div>
            <div key="post" {...item.post}>
              {item.post.children}
            </div>
          </div>
        </QueueAnim>

        {!isMobile && textWrapper}
      </div>
    );
  };

  render() {
    const { ...props } = this.props;
    const { dataSource,isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const children = dataSource.block.children.map(this.getBlockChildren);
    const { current,infoList,yearList } = this.state;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <Steps current={current} onChange={this.onChange}>
            {/*<Step key="2020" icon={<Avatar style={{ backgroundColor: '#0261A4', verticalAlign: 'middle' }} size="large">2020</Avatar>}/>*/}
            {/*<Step key="2021" icon={<Avatar style={{ backgroundColor: current<1?'#DCDFE6':'#0261A4', verticalAlign: 'middle' }} size="large">2021</Avatar>}/>*/}
            {/*<Step key="2022" icon={<Avatar style={{ backgroundColor: current<2?'#DCDFE6':'#0261A4', verticalAlign: 'middle' }} size="large">2022</Avatar>}/>*/}
            {/*<Step key="2023" icon={<Avatar style={{ backgroundColor: current<3?'#DCDFE6':'#0261A4', verticalAlign: 'middle' }} size="large">2023</Avatar>}/>*/}
            {
              yearList.map((item,i)=>(
                  <Step key="2021" icon={<Avatar style={{ backgroundColor: current<i?'#DCDFE6':'#0261A4', verticalAlign: 'middle' }} size="large">{item}</Avatar>}/>
              ))
            }
          </Steps>
          <div className='block-wrapper' style={{display: 'flex',marginTop:'50px', alignItems: 'center',justifyContent: 'center'}}>
            <QueueAnim
                type='bottom'
                key="text2"
                leaveReverse
                ease={['easeOutCubic', 'easeInCubic']}
            >
            <Timeline key={1} mode="alternate" style={{width:!isMobile?'500px':'90vw',paddingTop:'20px'}}>
              {
                infoList.map((item,i)=>(
                    <Timeline.Item key={i} label={<div className='blueFont' style={{fontSize:'16px',fontWeight:'600'}}>{Number(item.initDate.substring(5,7))}月</div>}>{item.content}</Timeline.Item>
                ))
              }
              {/*<Timeline.Item label={<div className='blueFont' style={{fontSize:'16px',fontWeight:'600'}}>6月</div>}>2021年6月18日根据《烟台市高新技术企业培育库建设管理实施细则（修订）》和《2021年度烟台市高新技术企业培育库工作实施方案》等文件要求，山东凌然智能科技有限公司正式通过高新技术企业培育库的严格审核和评估！ </Timeline.Item>*/}

            </Timeline>
            </QueueAnim>
          </div>

        </div>
        {!isMobile?
            <div style={{position:'absolute',right:'20px',bottom:'10px'}}><img src={bjlr} alt=""/></div>
            :null}
      </div>
    );
  }
}

export default Content9;
