import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";

const { BgElement } = Element;
class Banner extends React.PureComponent {
    constructor ( props ) {
        super(props)
        this.state = {
            bannerList:[]
        }
    }
    clickB=(item)=>{
       this.props.history.push({pathname:item.jumpUrl,state:item})
    };
    componentDidMount() {
        get('lrWebBanner/list',{moduleType:1,isShow:1,column: 'sortNum', order: 'asc'}).then(res => {
            if(res.success){
                this.setState({bannerList:res.result.records})
                //初始化image对象
                for (let i = 0; i < res.result.records.length; i++){
                    let img = new Image();
                    img.src = baseFileUrl+res.result.records[i].bannerUrl;
                }
            }
        })
    }
  render() {
    const { bannerList } = this.state;
    const childrenToRender = bannerList.map((item, i) => {
      return (
        <Element key={i.toString()} prefixCls="banner-user-elem">
          <BgElement key="bg" className="bg"
             style={{
                 backgroundImage: `url(${baseFileUrl+item.bannerUrl})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
             }}
          />
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            className="banner1-text-wrapper"
          >
            <div key="logo" className="banner1-title">
                {item.title||''}
            </div>
            <div key="content" className="banner1-content">
              {item.titleSub||''}
            </div>
              {
                  item.isJump?(
                      <Button type="primary" shape="round" key="button"  onClick={() =>this.clickB(item)}>
                          {item.linkName||'点击了解'}
                      </Button>
                  ):null
              }

          </QueueAnim>
        </Element>
      );
    });
    return (
      <div className="banner1">
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <div className="banner1-wrapper" key="wrapper">
            <BannerAnim key="BannerAnim"
                        //type="across"
                        autoPlay>
              {childrenToRender}
            </BannerAnim>
          </div>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner1-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}

export default Banner;
