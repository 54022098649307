/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner1 from './Banner1';
import Font1 from './Font1';
import Banner2 from './Banner2';
import Feature6 from './Feature6';

import {Banner11DataSource, Feature20DataSource, News0DataSource} from './data.source';
import { Banner20DataSource } from './data.source';
import { Feature60DataSource,Feature70DataSource,Teams20DataSource } from './data.source';
import './less/antMotionStyle.less';
import Banner3 from "./Banner3";
import Feature7 from "./Feature7";
import Teams2 from "./Teams2";
import Feature2 from "./Feature2";
import Font2 from "./Font2";
import News0 from "./News0";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Banner1
        id="Banner1_1"
        key="Banner1_1"
        dataSource={Banner11DataSource}
        history={this.props.history}
        isMobile={this.state.isMobile}
      />,
        <Font1 key="Font1"/>,
        <Banner2
            id="Banner2_0"
            key="Banner2_0"
            dataSource={Banner20DataSource}
            history={this.props.history}
            isMobile={this.state.isMobile}
        />,
      <Feature6
          id="Feature6_0"
          key="Feature6_0"
          dataSource={Feature60DataSource}
          history={this.props.history}
          isMobile={this.state.isMobile}
      />,
        <Banner3 key="Banner3_0" history={this.props.history}/>,
      <Feature7
          id="Feature7_0"
          key="Feature7_0"
          dataSource={Feature70DataSource}
          history={this.props.history}
          isMobile={this.state.isMobile}
      />,
      <Teams2
          id="Teams2_0"
          key="Teams2_0"
          dataSource={Teams20DataSource}
          history={this.props.history}
          isMobile={this.state.isMobile}
      />,
      <Feature2
          id="Feature2_0"
          key="Feature2_0"
          dataSource={Feature20DataSource}
          history={this.props.history}
          isMobile={this.state.isMobile}
      />,
      <Font2 isMobile={this.state.isMobile}/>,
      <News0
          id="News0_0"
          key="News0_0"
          dataSource={News0DataSource}
          history={this.props.history}
          isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {children}
      </div>
    );
  }
}
