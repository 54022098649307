import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';

import { getChildrenToRender } from './utils';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";
class Feature7 extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            infoList: []
        };
    }

    componentDidMount() {
        get('lrWebCase/list', {isShow: 1,homeShow:1, column: 'sortNum', order: 'asc'}).then(res => {
            if (res.success) {
                this.setState({infoList: res.result.records})
            }
        })
    }

    render() {
        const {infoList} = this.state;
        const childrenToRender = infoList.map((item, i) => (
            <Col md={8} xs={24} className='feature7-block' key={i.toString()}>
                <a className='feature7-block-group'>
                    <img src={baseFileUrl+item.caseImg} width="100%" height="200" alt="img"/>
                    <div style={{height: '178px', padding: '15px'}}>
                        <div className="blackFont" style={{fontSize: '18px', fontWeight: 'bold'}}>{item.caseTitle}</div>
                        <div className="blackFont" style={{height: '90px', fontSize: '13px',color:'#aaa'}}>{item.caseSketch}</div>
                        <Row>
                            <Col span={8} style={{textAlign: 'left'}}>&nbsp;
                                {/*<img src={baseFileUrl+item.custLogo} width="80" height="30" alt="img"/>*/}
                            </Col>
                            <Col span={16}
                                 style={{textAlign: 'right', paddingTop: '10px',fontSize:'12px',color:'#aaa'}}>{item.caseTips}</Col>
                        </Row>
                    </div>
                </a>
            </Col>
        ));
        return (
            <div className='home-page-wrapper feature7-wrapper'>
                <div className='home-page feature7'>
                    <div className="feature7-title-wrapper blueFont bigTitle">
                        凌然科技助力更多企业加快信息化、数字化、智能化转型
                    </div>
                    <OverPack playScale={0.3}>
                        <QueueAnim
                            key="queue"
                            type="bottom"
                            leaveReverse
                            interval={50}
                            component={Row}
                            className='feature7-block-wrapper'
                            gutter={24}
                        >
                            {childrenToRender}
                        </QueueAnim>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Feature7;
