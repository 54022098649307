import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";
class Feature7 extends React.PureComponent {
    constructor ( props ) {
        super(props)
        this.state = {
            hangyeList:[],
            infoList:[]
        }
    }
    componentDidMount() {
        get('lrWebCase/list',{isShow:1,column: 'sortNum', order: 'asc',pageSize:100}).then(res => {
            if(res.success){
                this.setState({infoList:res.result.records})
            }
        })
    }
render() {
    const { hangyeList} = this.props;
    console.log(hangyeList);
    const { infoList} = this.state;
    let dataSource=[];
    for (let i = 0; i < hangyeList.length; i++) {//1409411450894532610
        for (let j = 0; j < infoList.length; j++) {
            if(hangyeList[i].id===infoList[j].indutryType){
                dataSource.push(infoList[j]);
            }
        }
    }
    const childrenToRender =()=>dataSource.map((item2,k)=>(
                <Col  md={12} xs={24} className='feature7-block' key={'child'+k.toString()}>
                    <div key={'title'+k} className="feature7-title-sub">
                        {item2.indutryType_dictText}
                    </div>
                    <a className='feature7-block-group'>
                        <img src={baseFileUrl+item2.caseImg} width="100%" height="200" alt="img" />
                        <div style={{height:'188px',padding:'15px'}}>
                            <div className="blackFont" style={{fontSize:'18px',fontWeight:'bold'}}>{item2.caseTitle}</div>
                            <div className="blackFont" style={{height:'100px',fontSize:'13px',color:'#999'}} >{item2.caseSketch}</div>
                            <Row>
                                <Col className="blackFont" span={24} style={{paddingTop:'15px',fontSize:'12px',color:'#aaa'}}>{item2.caseTips}</Col>
                            </Row>
                        </div>
                    </a>
                </Col>
            ))
    return (
        <div className='home-page-wrapper feature7-wrapper'>
            <div className='home-page feature7'>
                <QueueAnim
                    key={"queue"}
                    type="bottom"
                    leaveReverse
                    interval={50}
                    component={Row}
                    className='feature7-block-wrapper'
                    gutter={88}
                >
                {childrenToRender()}
                </QueueAnim>
            </div>
            <div style={{textAlign:'center',width:'100%',display:'flex',justifyContent: 'center',marginBottom:'60px'}}>
                <div className='whiteFont' style={{background:'#0067ED',maxWidth:'1200px',width:'100%',padding:'15px 0',fontSize:'18px'}}>更多行业案例持续更新中，敬请期待~</div>
            </div>
        </div>
    );
}


}

export default Feature7;
