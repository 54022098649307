import React from 'react';
import TweenOne from 'rc-tween-one';
import  { Menu,Row,Col,Button } from 'antd';
import { getChildrenToRender } from './utils';
import {Link} from "react-router-dom";
import logo from '../static/logo.png'
import phone from '../static/service-phone.png'
import HoverButton from "../components/HoverButton";

const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };
    getSelectedMenu = () => {
        let pathname=this.props.pathname;
        const navData = this.props.dataSource.Menu.children;
        let selectedKeys=[];
        for (let i = 0; i < navData.length; i++) {
            if(navData[i].children.href===pathname){
                selectedKeys.push(navData[i].name)
            }
        }
        return selectedKeys
    }
    clickSubMenu = (topath) => {
        let pathname=this.props.pathname;
        if(topath!==pathname){
            this.props.history.push(topath)
            console.log(1112221);
            document.documentElement.scrollTop=0
        }
    }
    clickLogo = () => {
        this.props.history.push('/')
        document.documentElement.scrollTop=0
    }
    item3Btn = (key) => {
        this.props.history.push({pathname:'page4',state:{current:key}})
    }
    item4Btn = (key) => {
        this.props.history.push({pathname:'page5',state:{current:key}})
    }
    item5Btn = (key) => {
        this.props.history.push({pathname:'page6',state:{current:key}})
        // if(key==='2'){
        //     setTimeout(()=>{
        //         document.documentElement.scrollTop=document.getElementById('zxzx').scrollHeight+6
        //     },100)
        // }
        // if(key==='3'){
        //     setTimeout(()=>{
        //         document.documentElement.scrollTop=document.getElementById('zxzx').scrollHeight+806
        //     },100)
        // }
    }
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      const hasSub=['item1','item3','item4','item5'];
      if (hasSub.includes(item.name)&&!isMobile) {
          let content,buttons;
          let selectedItem=this.getSelectedMenu().length?this.getSelectedMenu()[0]:''
          if(item.name==='item1'){
              content=(
                  <Col span={12} className='whiteFont'>
                      <div style={{paddingLeft:'8vw',paddingTop:'30px'}}>
                          <div style={{fontSize:'20px',fontWeight:'bold'}}>产品与服务</div>
                          <div>凌然作为国家级科技型企业，专注致力于企业转型升级方案设计及信息化</div>
                          <div>软件研发，利用互联网技术，为企业提供灵活可配的管理平台及应用。</div>
                      </div>
                  </Col>
              )
              buttons=(
                  <Col span={8}>
                      <Row gutter={0} style={{paddingTop:'25px'}}>
                          <Col span={8}><HoverButton name='>>应用场景化'/></Col>
                          <Col span={8}><HoverButton name='>>计算高效化'/></Col>
                          <Col span={8}><HoverButton name='>>链接智能化'/></Col>
                      </Row>
                      <Row gutter={0} style={{paddingTop:'20px'}}>
                          <Col span={8}><HoverButton name='>>资源数据化'/></Col>
                      </Row>
                  </Col>
              )
          }
          else if(item.name==='item3'){
              content=(
                  <Col span={12} className='whiteFont'>
                      <div style={{paddingLeft:'8vw',paddingTop:'30px'}}>
                          <div style={{fontSize:'20px',fontWeight:'bold'}}>了解凌然</div>
                          <div>山东凌然智能科技有限公司，是国家高新技术型企业培育库入库企业、信通院工业互联网产业联盟</div>
                          <div>会员单位、荣获烟台市创新竞技三等奖。凌然科技致力于工业企业转型升级方案设</div>
                          <div>计及信息化软件研发，提供多款微服务架构产品，为企业提供转型一站式服务。</div>
                      </div>
                  </Col>
              )
              buttons=(
                  <Col span={8}>
                      <Row gutter={0} style={{paddingTop:'25px'}}>
                          <Col span={8}>
                              <HoverButton name='>>企业介绍' onClick={()=>this.item3Btn('1')}/>
                          </Col>
                          <Col span={8}><HoverButton name='>>企业文化' onClick={()=>this.item3Btn('2')}/></Col>
                          <Col span={8}><HoverButton name='>>发展履历' onClick={()=>this.item3Btn('3')}/></Col>
                      </Row>
                      <Row gutter={0} style={{paddingTop:'20px'}}>
                          <Col span={8}><HoverButton name='>>企业荣誉' onClick={()=>this.item3Btn('4')}/></Col>
                      </Row>
                  </Col>
              )
          }else if(item.name==='item4'){
              content=(
                  <Col span={12} className='whiteFont'>
                      <div style={{paddingLeft:'8vw',paddingTop:'30px'}}>
                          <div style={{fontSize:'20px',fontWeight:'bold'}}>欢迎进入开放的工业互联网世界</div>
                          <div>这里有最新鲜的政策动态、行业资讯，最全面的行业活动攻略，这里有你需要的一切！</div>
                      </div>
                  </Col>
              )
              buttons=(
                  <Col span={8}>
                      <Row gutter={0} style={{paddingTop:'25px'}}>
                          <Col span={8}><HoverButton name='>>公司新闻' onClick={()=>this.item4Btn('1')}/></Col>
                          {/*<Col span={8}><HoverButton name='>>媒体报道' onClick={()=>this.item4Btn('2')}/></Col>*/}
                          <Col span={8}><HoverButton name='>>行业资讯' onClick={()=>this.item4Btn('3')}/></Col>
                          <Col span={8}><HoverButton name='>>专业知识' onClick={()=>this.item4Btn('4')}/></Col>
                      </Row>
                      {/*<Row gutter={0} style={{paddingTop:'20px'}}>*/}
                      {/*    <Col span={8}><HoverButton name='>>专业知识' onClick={()=>this.item4Btn('4')}/></Col>*/}
                      {/*</Row>*/}
                  </Col>
              )
          }else if(item.name==='item5'){
              content=(
                  <Col span={12} className='whiteFont'>
                      <div style={{paddingLeft:'8vw',paddingTop:'30px'}}>
                          <div style={{fontSize:'20px',fontWeight:'bold'}}>加入凌然 共飨工业互联网使命</div>
                          <div>汇聚优质合作伙伴，共建工业互联网新生态！大力引进高层次创新创业人才，致力于</div>
                          <div>提供安全和高价值的产品与服务，凌然科技期待您的加入。</div>
                      </div>
                  </Col>
              )
              buttons=(
                  <Col span={8}>
                      <Row gutter={0} style={{paddingTop:'25px'}}>
                          <Col span={8}><HoverButton name='>>招贤纳士' onClick={()=>this.item5Btn('1')}/></Col>
                          <Col span={8}><HoverButton name='>>平台服务在线咨询' onClick={()=>this.item5Btn('2')}/></Col>
                          <Col span={8}><HoverButton name='>>联系我们' onClick={()=>this.item5Btn('3')}/></Col>
                      </Row>
                  </Col>
              )
          }
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            onTitleClick={()=>this.clickSubMenu(a.href)}
            title={
              <div {...a} className={`header3-item-block ${a.className}`.trim()}>
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
              {
                  selectedItem!==item.name?
                      <Item key={item.name+'item'} style={{width:'100vw',height:'160px',backgroundColor:'rgba(39, 119, 191,0.7)',boxShadow:'0 3px -1px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)!important' }}>
                          <Row>
                              {content}
                              {buttons}
                          </Row>
                      </Item>
                      :null
              }
          </SubMenu>
        );
      }

      return (
        <Item key={item.name} {...itemProps}>
          <Link {...a} className={`header3-item-block ${a.className}`.trim()} onClick={()=>this.clickSubMenu(a.href)}>
            {a.children.map(getChildrenToRender)}
          </Link>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        className="header3 home-page-wrapper"
        style={{position: 'fixed',
            top: 0}}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
          style={{maxWidth:'2560px'}}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            className='header3-logo'
          >
            <img src={logo} width="100%" alt="img" onClick={()=>this.clickLogo()}/>
          </TweenOne>
            <TweenOne
                animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                className='header3-phone'
            >
                <img width="22" height={22} src={phone} alt="img" /> <span className='blueFont' style={{fontSize:'bold'}}>400-678-7996</span>
            </TweenOne>
          {isMobile && (
            <div
              className="header3-mobile-menu"
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              selectedKeys={this.getSelectedMenu()}
              theme="light"
              // defaultOpenKeys={['item1','item']}
            >
              {navChildren}
            </Menu>
          </TweenOne>

        </div>
      </TweenOne>
    );
  }
}

export default Header3;
