/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';


import { Banner00DataSource } from './data.source';
import './less/antMotionStyle.less';
import Banner0 from "./Banner0";
import Content6 from "../Page6/Content6";
import {Content60DataSource} from "./data.source";
import zwxqbj from '../static/zwxq-bj.png'
import tb from '../static/tb.png'
import jt from '../static/jt.png'
import {Row,Col} from "antd";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class recruit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      item:{}
    };
  }

  componentDidMount() {
    console.log(this.props.location.state);
    this.setState({item:this.props.location.state.item})
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  showInfo=(item)=>{
    this.setState({item:item})
  }
  render() {
    const {infoList}=this.props.location.state
    const {item}=this.state
    let newList=[];
    for (let i = 0; i < infoList.length; i++) {
      if(infoList[i].id!==item.id){
        newList.push(infoList[i]);
      }
    }
    const children = [
      <Banner0
          id="Banner0_0"
          key="Banner0_0"
          dataSource={Banner00DataSource}
          isMobile={this.state.isMobile}
      />,
      <div className='home-page-wrapper content6-wrapper ' style={{background:`url(${zwxqbj})`}}>
        <div className='home-page content6' style={{padding:'20px 20px'}}>
          <h2>{item.joinPosition}</h2>
          <div>{item.address}|{item.updateTime?`${item.updateTime.substring(0,4)}年${item.updateTime.substring(5,7)}月${item.updateTime.substring(8,10)}日`:item.createTime?`${item.createTime.substring(0,4)}年${item.createTime.substring(5,7)}月${item.createTime.substring(8,10)}日`:''}</div>
          <hr/>
          <div key={item.id+'5'} dangerouslySetInnerHTML={{__html:item.joinContent}} />
          <h2 style={{paddingTop:'30px'}}><img src={tb} height={22} alt="" style={{marginBottom:'5px'}}/> 其他推荐岗位</h2>
          {
            newList.map((item)=>(
                <Row style={{boxShadow: '0 0 5px rgba(66, 66, 224, 0.15)',background:'#fff',marginTop:'20px',padding:'20px 30px'}}  onClick={()=>this.showInfo(item)}>
                  <Col span={18}>
                    <h2>{item.joinPosition}</h2>
                    <div>{item.address}|{item.updateTime?`${item.updateTime.substring(0,4)}年${item.updateTime.substring(5,7)}月${item.updateTime.substring(8,10)}日`:`${item.createTime.substring(0,4)}年${item.createTime.substring(5,7)}月${item.createTime.substring(8,10)}日`}</div>
                    <div style={{fontSize:'12px',color:'#aaaaaa'}}>{item.joinPlace}</div>
                  </Col>
                  <Col span={6}style={{paddingTop:'15px',textAlign:'right'}}>
                    <img src={jt} alt="" />
                  </Col>
                </Row>
            ))
          }
        </div>
      </div>,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {children}
      </div>
    );
  }
}
