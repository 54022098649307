/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner31 from './Banner31';
import {Banner31DataSource} from './data.source';
import './less/antMotionStyle.less';
import Feature32 from "../Page3/Feature32";
import Feature7 from "../Page3/Feature7";
import {Feature70DataSource} from "./data.source";
import {get} from "../utils/http";


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      hangyeList:[]
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });

    get('lrWebBusiness/list',{isShow:1,column: 'sortNum', order: 'asc',pageSize:100}).then(res => {
      if(res.success){
        this.setState({hangyeList:res.result.records})
      }
    })
  }

  render() {
    const children = [
      <Banner31
        id="Banner31_1"
        key="Banner31_1"
        dataSource={Banner31DataSource}
        history={this.props.history}
        isMobile={this.state.isMobile}
      />,
      <Feature32
          id="Feature32_0"
          key="Feature32_0"
          isMobile={this.state.isMobile}
          hangyeList={this.state.hangyeList}
      />,
      <Feature7
          id="Feature7_0"
          key="Feature7_0"
          dataSource={Feature70DataSource}
          isMobile={this.state.isMobile}
          hangyeList={this.state.hangyeList}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {children}
      </div>
    );
  }
}
