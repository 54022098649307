import React from 'react';
import {Row,Col} from "antd";
import './less/custom.less'
import yun from '../static/yun.png'
import shuju from '../static/shuju.png'
import fuwu from '../static/fuwu.png'
class Banner extends React.PureComponent {
    render() {
        return(
            <div>
                <Row className="whiteFont font1-title1" style={{background:'#1158e8' }}>
                    <Col span={8}  className='font1-img'><img src={yun} />  专业级智能云管理服务</Col>
                    <Col span={8}  className='font1-img'><img src={shuju}/>  覆盖行业46+</Col>
                    <Col span={8}  className='font1-img'><img src={fuwu} />  服务企业客户300家</Col>
                </Row>
                <Row className="blackFont font1-title">
                    <Col span={24} style={{textAlign:'center',paddingTop:'50px'}}>提供集生产、制造、运营和服务于一体的全流程解决方案</Col>
                </Row>
                <Row className="blackFont font1-text">
                    <Col span={24} style={{textAlign:'center',paddingBottom:'50px'}}>
                        国内首个跨行业工业数据服务集成管理平台，具备强大的数据处理能力，<br/>提供开放应用对接，为客户提供更自由、更全面、更快捷的数据赋能收益
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Banner;
