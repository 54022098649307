import React from 'react';
class HoverButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
        };
    }

    setHover = (flag) => {
        this.setState({isHover:flag})
    }

    render() {
        let width=this.props.width||'130px'
        let height=this.props.height||'35px'
        let fontSize=this.props.fontSize||'13px'
        let fontWeight=this.props.fontSize||'600'
        let name=this.props.name||'按钮'
        return (
            <div onClick={this.props.onClick} style={{fontSize,fontWeight,width,height,lineHeight:height,borderRadius:'5px',backgroundColor:this.state.isHover?'#fff':'#F39A00',color:this.state.isHover?'#2864E5':'#fff',textAlign:'center'}} onMouseOver={()=>this.setHover(true)}
                 onMouseOut={()=>this.setHover(false)}>{name}</div>
        );
    }
}

export default HoverButton;
