import React from 'react';
import {Row,Col,Button} from "antd";
import './less/custom.less'
import peoplew from '../static/people-network.png'
class Banner extends React.PureComponent {
    render() {
        return(
            <div style={{background:'#1158e8',display:'flex',justifyContent: 'center' }}>
                <Row className="whiteFont font2-title1"  style={{width:'1200px' }}>
                    <Col span={this.props.isMobile?24:22} className='font2-img' style={{textAlign:'left'}}><img src={peoplew} alt=""/>&nbsp;&nbsp;&nbsp;
                        凌然科技与您的企业一同实现数字化管理，见证智能产业的辉煌和成就。+</Col>
                    <Col span={this.props.isMobile?24:2} className='font2-img' style={{textAlign:'right'}}><Button ghost>欢迎了解>></Button></Col>
                </Row>
            </div>
        )
    }
}
export default Banner;
